<template>
  <div class="two-columns">
    <span>{{ message.client.name }}</span>
    <span class="two-columns__title">{{ message.client.username }}</span>
    <span v-if="isWhatsapp" class="two-columns__title warning">{{ expiredTime() }}</span>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      require: true,
      type: Object,
    },
  },
  data() {
    return {
      currentTime: new Date(),
    };
  },
  computed: {
    session24h() {
      return this.$moment(this.message.expiresAt);
    },
    duration() {
      return this.$moment.duration(this.session24h.diff(this.currentTime));
    },
    isWhatsapp() {
      return this.message.channel?.channelType?.key === 'whatsapp';
    },
  },
  mounted() {
    this.time = setInterval(() => {
      this.currentTime = this.$moment();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.time);
  },
  methods: {
    expiredTime() {
      if (this.duration.hours() > 0) {
        return `${this.$t('messages.expires')}: ${this.duration.hours()}h ${this.duration.minutes()}m`;
      }
      if (this.duration.asSeconds() > 0) {
        return `${this.$t('messages.expires')}: ${this.duration.minutes()}m ${this.duration.seconds()}s`;
      }

      return this.$t('messages.expired');
    },
  },
};

</script>

<style lang="scss" scoped>
  .warning {
    color: $red;
  }
</style>
